import React from "react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import config from "../../data/SiteConfig";
import Layout from "../layout";
import * as S from "../styles/styles.css";

function OutdoorPage() {
  return (
    <Layout location="outdoor" title={false}>
      <Helmet title={`About | ${config.siteTitle}`} />
      <S.StandardContainer>
        <Row style={{ minHeight: "calc(100vh - 104px)" }}>
          <Col>
            <h3>33 Fruits Organics</h3>
            <p>Farmhand and Horticulture/Forestry</p>
            {/* <p>Lorem ispum dolor sit amet, consectetur adipiscing el</p>
            <p>
              Lorem ispum dolor sit amet, consectetur adipiscing el Lorem ispum
              dolor sit amet, consectetur adipiscing el Lorem ispum dolor sit
              amet, consectetur adipiscing el
            </p>
            <h3>Photo Gallery</h3>
            <div
              style={{
                width: "100%",
                height: "600px",
                display: "block",
                borderRadius: "5px",
                background: "linear-gradient(green, teal 45%)",
              }}
            /> */}
          </Col>
        </Row>
      </S.StandardContainer>
    </Layout>
  );
}

export default OutdoorPage;
